import Fade from '@material-ui/core/Fade';
import React, {useEffect, useRef, useState} from "react";
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleReactValidator from 'simple-react-validator';
import CoreConstants from "../../constants/CoreConstants";
import {fetchLeadFlow} from "../../actions/leadflowAction";
import {connect} from "react-redux";
import { fetchCalendars, assignCalendarToLeadFlow } from "../../api/leadflowApi";
import Utils from '../../helpers/Utils';
import Loading from "../Common/Loading";
import If, {Else} from 'if-else-react';

const AssignCalendarModal = props => {

    const validator = useRef(new SimpleReactValidator())
    const [loading, setLoading] = useState(true);
    const [calendars, setCalendars] = useState([]);
    const [selectedCalendar, setSelectedCalendar] = useState('');

    useEffect(() => {
        fetchCalendars().then(response => {
            setLoading(false);
            if (response.data.status) {
                setCalendars(response.data.calendars);
            } else {
                Utils.showNotification(response.data.message, 'error');
            }
        })
    }, []);

    const submitAssignCalendarFlow = () => {
        if (validator.current.allValid()) {
            assignCalendarToLeadFlow({calendarId: selectedCalendar, leadflowId : props.selectedLeadFlow.id}).then(response => {
                if (response.data.status) {
                    props.fetchLeadFlow();
                    props.setOpenCalendarModal(false);
                    Utils.showNotification(response.data.message, 'success');
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            });
        } else {
            validator.current.showMessages();
        }
    }

    const renderCalendar = () => {
        let calendarOptions = [];

        if (calendars.length) {
            calendarOptions.push(<MenuItem value="" disabled>Select Calendar</MenuItem>);
            calendars.map(calendar => {
                calendarOptions.push(<MenuItem value={ calendar.id }>{calendar.title}</MenuItem>);
            });
        } else {
            calendarOptions.push(<MenuItem value="" disabled>No calendar found in system</MenuItem>);
        }
        return calendarOptions;
    }

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                // className={classes.modal}
                open={props.openCalendarModal}
                onClose={props.setOpenCalendarModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openCalendarModal}>
                    <div className="add_leadflow_modal" id="add_new_leadFlow_modal">
                        <div className="top_content d-flex justify-content-between align-items-center p-4">
                            <div className="sub_title">
                                <h2>Assign calendar in leadflow</h2>
                            </div>
                            <div className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={(e) => props.setOpenCalendarModal(false)}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
                                          fill="white"></path>
                                    <path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
                                          fill="white"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="input_field_group">
                            <div className="row">
                                <div className="col s12 lead_src_select">
                                    <label>Select Schedule Calendar<span className="check_icon">*</span></label>
                                    <If condition={loading}>
                                        <Loading />
                                    <Else />
                                        <Select
                                            labelId="personalize-select-label"
                                            id="personalize-select"
                                            name={"calendar"}
                                            placeholder={"Select Calendar"}
                                            value={selectedCalendar}
                                            onChange={(e) => setSelectedCalendar(e.target.value)}
                                            displayEmpty
                                        >
                                            { renderCalendar() }
                                        </Select>
                                    </If>
                                    {validator.current.message('calendar', selectedCalendar, 'required|alpha num')}
                                </div>
                            </div>
                        </div>
                        <div className="modal__buttons d-flex justify-content-center align-content-center">
                            <button type="buton" className="add__group__btn accent--bg--color" onClick={() => submitAssignCalendarFlow()}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="white"/>
                                    <path
                                        d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z"
                                        className={"accent--fill--color"}/>
                                </svg>
                                <span className="mx-2">Assign With Calendar</span>
                            </button>
                            <button onClick={() => props.setOpenCalendarModal(false)} type='button' className="modal-close modal__close__btn">
                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.1016 3.90156C14.2016 0.0015626 7.80156 0.0015626 3.90156 3.90156C0.00156271 7.80156 0.00156271 14.2016 3.90156 18.1016C7.80156 22.0016 14.1016 22.0016 18.0016 18.1016C21.9016 14.2016 22.0016 7.80156 18.1016 3.90156ZM13.8016 15.2016L11.0016 12.4016L8.20156 15.2016L6.80156 13.8016L9.60156 11.0016L6.80156 8.20156L8.20156 6.80156L11.0016 9.60156L13.8016 6.80156L15.2016 8.20156L12.4016 11.0016L15.2016 13.8016L13.8016 15.2016Z"
                                        fill="white"/>
                                </svg>
                                <span className="mx-2">Close</span>
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    leadFlows: state.leadFlowReducer.leadFlows
});

const mapDispatchToProps = dispatch => ({
    fetchLeadFlow : () => dispatch(fetchLeadFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignCalendarModal);
